import React, {
  createContext,
  FC,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { createCamerasConnection, IEntity, listEntities } from '@netvision/lib-api-gateway'
import { useMountedRef } from '../../hooks/useMountedRef'
import { createBatchQueue, IBatchQueue } from '../../utils/batchQueue'

declare module '@netvision/lib-api-gateway' {
  interface IEntity {
    title?: string
  }
}

const useEntity = (type: string, id: string): IEntity | undefined => {
  const mountedRef = useMountedRef()
  const fetch = useContext(EntityFetchCtx)
  const [entity, setEntity] = useState<IEntity | undefined>()
  useEffect(() => {
    setEntity(undefined)
    fetch(type, id)
      .then(setEntity)
      .catch(() => setEntity(undefined))
  }, [fetch, type, id, mountedRef])
  return entity
}

export const Entity: FC<{ id: string; type: string }> = ({ type, id }) => {
  const entity = useEntity(type, id)
  return <Fragment>{entity?.title || null}</Fragment>
}

export const EntityFetchProvider: FC = ({ children }) => {
  type Q = IBatchQueue<[string, string], IEntity>
  const [batchMap] = useState(() => new Map<string, Q>())
  const getEntity = useCallback(
    (type: string, id: string): Promise<IEntity> => {
      return new Promise<IEntity>((resolve, reject) => {
        // Only queue per each entity
        // so multiple rows with same entity will make only one request
        // TODO make one per type, when support for comma-separated list of ids will be fixed on backend
        const key = type + id
        let q: Q | undefined = batchMap.get(key)
        if (typeof q === 'undefined') {
          q = createEntityQueue()
          batchMap.set(key, q)
        }
        q.add([type, id], reject, resolve)
      })
    },
    [batchMap],
  )
  return <EntityFetchCtx.Provider value={getEntity}>{children}</EntityFetchCtx.Provider>
}

const createEntityQueue = () =>
  createBatchQueue<[string, string], IEntity>(16, (input) => {
    if (input.length > 0) {
      const [type, id] = input[0].args
      return loadEntities({ type, id }).then((res) => {
        if (res[0]) {
          return input.map(({ $id }) => ({ $id, result: res[0] }))
        } else {
          return []
        }
      })
    } else {
      return Promise.resolve([])
    }
  })

const EntityFetchCtx = createContext<(type: string, id: string) => Promise<IEntity>>(null!)

const loadEntities = <E extends IEntity>(params: {
  type: string
  id?: string
  q?: string
  limit?: number
  orderBy?: string
}): Promise<E[]> =>
  listEntities<E>(createCamerasConnection(), {
    ...params,
  })
    .then(({ results }) => (results.every(isObject) ? results : []))
    .catch(() => [])

const isObject = <T extends object>(v: unknown): v is T => typeof v === 'object' && v !== null
