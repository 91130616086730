import React, { useMemo, useEffect, useRef, useCallback } from 'react'
import {
  Table,
  IColumn,
  IFilterDescription,
  IColumnsSettings,
  applyColumnsSettings,
  INgsiOnLoadEvent,
  isString,
} from '@netvision/lib-react-table'
import { useLocale } from '../hooks/useLocale'
import {
  createCamerasConnection,
  getSharedNotificationSocket,
  IAssignmentPriority,
  IEntity,
} from '@netvision/lib-api-gateway'
import { name } from '../../package.json'
import { Priority } from './helpers/Priority'
import { AssignmentGroup } from './helpers/AssignmentGroup'
import { factoryUseEntityList } from '../hooks/factoryUseEntityList'
import { Status } from './helpers/Status'
import { RefCell } from './helpers/RefCell'
import { EntityFetchProvider } from './helpers/Entity'
import { useEntitySearch } from '../hooks/factoryUseEntitySearch'
import { AssignmentType } from './helpers/AssignmentType'
import { AssignmentStatus, AssignmentRejectReason } from '../AssignmentStatus'
import { AssignmentPermissionsProvider } from './helpers/AssignmentPermissionsProvider'
import { ModalProvider } from './helpers/ModalProvider'
import { ForceLoadProvider } from './helpers/ForceLoadProvider'
import { Title } from './helpers/Title'
import { ToastProvider } from '../hooks/useToastRef'
import { IAssignment } from '../IAssignment'
import { getEntitiesById, getPresetsByTitle } from '../presetsApi'
import { IOption } from '@netvision/lib-react-table/dist/src/utils/types'
import type { ISearchFilter } from '@netvision/lib-react-table/dist/src/filterTypes'
import { optionsFromEntities, searchByField, searchById } from '../filterHelpers'
import { E2EModule } from '../__test__'

export const Main = () => {
  const locale = useLocale()
  const [assignmentByField] = useEntitySearch('Assignment')
  const [cameraByField] = useEntitySearch('Camera,Record,File')
  const priorities = useAssignmentPriorities()
  const prioritiesMap = useMap(priorities)
  const columns = useMemo(() => {
    const all: IColumn<IAssignment, IAvailableField>[] = [
      {
        field: 'groupId',
        header: locale.assignmentGroup,
        body: (rowData) => <AssignmentGroup rowData={rowData} />,
      },
      {
        field: 'title',
        header: locale.assignmentNameLabel,
        body: (a) => <Title value={a.title} error={!a.canStart} />,
      },
      {
        field: 'assignmentTypeId',
        header: locale.assignmentTypeId,
        body: (rowData) => <AssignmentType rowData={rowData} />,
      },
      {
        field: 'priorityId',
        header: locale.priorityLabel,
        body: (a) => {
          const p = prioritiesMap.get(a.priorityId)
          return typeof p === 'undefined' ? null : <Priority priority={p} />
        },
      },
      {
        field: 'entityTitle',
        header: locale.entityLabel,
        body: (rowData) => (
          <div data-cy={E2EModule.attributes.assignmentEventEntityTitle}>
            <RefCell
              rowData={rowData}
              description={{
                idField: 'entityId',
                typeStatic: rowData.entityType,
              }}
            />
          </div>
        ),
      },
      {
        field: 'entityId',
        header: locale.entityIdLabel,
        body: (a) => a.entityId,
      },
      {
        field: 'entityType',
        header: locale.entityTypeLabel,
        body: (a) => a.entityType,
      },
      {
        field: 'rejectReason',
        header: locale.rejectReason,
        body: (a) => (a.rejectReason ? locale.assignmentRejectReason[a.rejectReason] || '' : ''),
      },
      {
        field: 'status',
        header: locale.statusLabel,
        body: (a) => <Status assignment={a} />,
      },
      {
        field: 'resourceId',
        header: ''
      }
    ]
    return applyColumnsSettings(true, defaultColumnSettings(), undefined, all)
  }, [locale, prioritiesMap])
  const filterDescription = useMemo(() => {
    const fd: IFilterDescription<IAvailableField> = {
      title: {
        type: 'autocomplete',
        placeholder: locale.searchByName,
        minLength: 1,
        searchOptions: (query) =>
          assignmentByField(query, 'title', 30).then((res) =>
            res.map((a) => a.title).filter(isString),
          ),
      },
      assignmentTypeId: buildSearchFilterForRef({
        field: 'assignmentTypeId',
        entityType: 'AssignmentType',
        placeholder: locale.search,
      }),
      entityTitle: {
        type: 'search',
        placeholder: locale.searchByName,
        field: 'entityId',
        maxEntries: 1,
        minLength: 1,
        getOptionsByValues: (values: Array<string | number>) =>
          getEntitiesById(values.map(String), 'Camera').then(entityToOptions),
        searchOptions: (query) =>
          cameraByField(query, 'title', 30).then((res) =>
            res
              .map((a) => ({ value: a.id, label: a.title } as IOption<string>))
              .filter(({ label }) => isString(label)),
          ),
      },
      entityId: {
        type: 'text',
        placeholder: locale.search,
      },
      entityType: {
        type: 'text',
        placeholder: locale.search,
      },
      presetTitle: {
        type: 'search',
        field: 'parameters.preset',
        placeholder: locale.search,
        maxEntries: 1,
        minLength: 1,
        getOptionsByValues: (values: Array<string | number>) =>
          getEntitiesById(values.map(String), 'CameraPreset').then(entityToOptions),
        searchOptions: (query: string) => getPresetsByTitle(query).then(entityToOptions),
      },
      rejectReason: {
        type: 'enum',
        options: Object.values(AssignmentRejectReason).map((v) => ({
          label: locale.assignmentRejectReason[v],
          value: v,
        })),
      },
      status: {
        type: 'enum',
        options: Object.values(AssignmentStatus).map((v) => ({
          label: locale.assignmentStatusLocale[v],
          value: v,
        })),
      },
    }
    return fd
  }, [cameraByField, locale, priorities, assignmentByField])
  const defaultPage = useMemo(() => {
    return {
      rows: 10,
      rowsPerPage: [10, 25, 50, 100],
      sortField: 'groupId',
      invariableSortField: 'dateCreated',
      sortOrder: 1,
    }
  }, [])

  const currentAssignmentsRef = useRef<Map<string, IAssignment> | null>(null)
  const forceLoadRef = useRef<(() => void) | null>(null)
  const forceLoad = useCallback(() => {
    forceLoadRef.current?.()
  }, [])
  useEffect(() => {
    const socket = getSharedNotificationSocket()
    if (socket) {
      return socket.addListener<IAssignment>('Assignment', (res) => {
        const forceLoad = forceLoadRef.current
        const map = currentAssignmentsRef.current
        if (forceLoad && map) {
          if (map.has(res.id)) {
            forceLoad()
          }
        }
      })
    } else {
      return undefined
    }
  }, [])
  const dataSource = useMemo(
    () => ({
      type: 'Assignment',
      extraAttrs: ['canStart'],
      onLoad: (e: INgsiOnLoadEvent<IAssignment, string>) => {
        currentAssignmentsRef.current = toMap(e.result.data)
        forceLoadRef.current = e.forceLoad
      },
    }),
    [],
  )
  const localeOverrides = useMemo(
    () => ({
      noRecords: locale.noRecords,
    }),
    [locale],
  )
  return (
    <ToastProvider position={'bottom-left'}>
      <ForceLoadProvider value={forceLoad}>
        <AssignmentPermissionsProvider>
          <EntityFetchProvider>
            <ModalProvider>
              <div data-cy={E2EModule.attributes.tableWrapper}>
                <Table
                  title={locale.title}
                  dataKey={'id'}
                  dataSource={dataSource}
                  preferencesLocalStorageKey={`${name}:table-settings`}
                  defaultGroupingFields={['groupId']}
                  columns={columns}
                  defaultPage={defaultPage}
                  requireSortField={false}
                  filterDescription={filterDescription}
                  locale={localeOverrides}
                />
              </div>
            </ModalProvider>
          </EntityFetchProvider>
        </AssignmentPermissionsProvider>
      </ForceLoadProvider>
    </ToastProvider>
  )
}

const useAssignmentPriorities = factoryUseEntityList<IAssignmentPriority>(
  'AssignmentPriority',
  createCamerasConnection,
)

export type IAvailableField =
  | 'groupId'
  | 'title'
  | 'assignmentTypeId'
  | 'priorityId'
  | 'entityId'
  | 'entityType'
  | 'entityTitle'
  | 'status'
  | 'parameters.preset'
  | 'rejectReason'
  | 'presetTitle'
  | 'resourceId'

type ColumnsSettingsPinned = {
  [K in IAvailableField]: {
    requiredColumn?: true
    isPinnedGroup?: true
    isPropOnly?: true
  }
}

const defaultColumnSettings = (): IColumnsSettings<IAvailableField> &
  Partial<ColumnsSettingsPinned> => {
  return {
    groupId: { requiredColumn: true, sortable: false },
    title: { requiredColumn: true, sortable: false },
    assignmentTypeId: { requiredColumn: true, sortable: false },
    priorityId: { hideByDefault: true, sortable: false },
    entityTitle: { requiredColumn: true, sortable: false },
    entityId: { hideByDefault: true, sortable: false },
    entityType: { hideByDefault: true, sortable: false },
    // 'parameters.preset': { hideByDefault: true, sortable: false },
    presetTitle: { hideByDefault: true, sortable: false },
    rejectReason: { hideByDefault: true, sortable: false },
    status: { requiredColumn: true, sortable: false },
    resourceId: { hideByDefault: true, sortable: false, isPropOnly: true }
  }
}

const toMap = <K extends string | number, E extends { id: K }>(arr: Array<E>): Map<K, E> =>
  new Map(arr.map((v) => [v.id, v]))
const useMap = <K extends string | number, E extends { id: K }>(arr: Array<E>): Map<K, E> =>
  useMemo(() => toMap(arr), [arr])

const entityToOptions = (entity: IEntity[]) =>
  entity.map((p) => {
    return {
      value: p.id,
      label: p.title,
    } as IOption<string>
  })

const buildSearchFilterForRef = (options: {
  field?: string
  placeholder: string
  entityType: string
  labelField?: string | 'title'
  queryField?: string | 'title'
  limit?: number | 20
  maxEntries?: number | 1
  minLength?: number | 1
}): ISearchFilter => {
  const {
    field,
    placeholder,
    entityType,
    maxEntries = 10,
    minLength = 1,
    queryField = 'title',
    limit = 20,
    labelField = 'title',
  } = options
  return {
    type: 'search',
    field,
    placeholder,
    maxEntries,
    minLength,
    getOptionsByValues: (values: Array<string | number>) =>
      searchById(
        entityType,
        values.map((v) => v.toString()),
      ).then(optionsFromEntities(labelField)),
    searchOptions: (query: string) =>
      searchByField(entityType, query, queryField, limit).then(optionsFromEntities(labelField)),
  }
}
