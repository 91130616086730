/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { IAssignmentType } from '@netvision/lib-api-gateway'
import { useEntity } from '../../hooks/useEntity'
import { IAssignment } from '../../IAssignment'
import { E2EModule } from '../../__test__'

export type IJoinParameter = {
  fields: string[]
  separator?: string
}

export function AssignmentType(props: { rowData: IAssignment }) {
  const { rowData } = props
  const id = rowData.assignmentTypeId
  const [loading, _, assignmentType] = useEntity<IAssignmentType>('AssignmentType', id || '')
  const icon = assignmentType?.icon?.iconClass || ''
  const color = assignmentType?.icon?.color || undefined
  const title = assignmentType?.title || assignmentType?.name
  if (loading) {
    return null
  }
  return (
    <div data-cy={E2EModule.attributes.assignmentEventType} css={style}>
      <i className={`mdi mdi-20px ${icon}`} style={{ color }} />
      {title}
    </div>
  )
}

// language=SCSS
const style = css`
  & {
    display: flex;
    align-items: center;
    color: var(--text-color);
    font-weight: 400;
    font-size: 18px;
    i {
      color: var(--text-color);
      height: calc(24rem / var(--bfs));
      width: calc(24rem / var(--bfs));
      font-size: calc(24rem / var(--bfs));
      margin-right: calc(15rem / var(--bfs));
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`
