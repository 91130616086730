/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { FC, useMemo } from 'react'
import { get, isString } from 'lodash-es'
import { useEntity } from '../../hooks/useEntity'
import { IEntity } from '@netvision/lib-api-gateway'

export type IJoinParameter = {
  fields: string[]
  separator?: string
}

export function RefCell<T extends IEntity>(props: {
  rowData: T
  description: {
    idField: string
    typeField?: string
    typeStatic?: string
    join?: IJoinParameter
    picture?: string
  }
}) {
  const { rowData, description } = props
  const id = useMemo(() => get(rowData, description.idField) ?? '', [rowData, description])
  const type = useMemo(() => {
    if (description.typeStatic) {
      return description.typeStatic
    } else if (description.typeField) {
      return get(rowData, description.idField) ?? ''
    } else {
      return ''
    }
  }, [rowData, description])
  const [loading, _, entity] = useEntity(type, id)
  const text = useMemo(() => {
    if (entity === null) {
      return ''
    } else {
      const { join = { fields: ['title'] } } = description
      return applyJoin(entity, join)
    }
  }, [entity, description])
  if (loading) {
    return null
  }
  return text.length === 0 ? (
    <span>{'-'}</span>
  ) : (
    <span css={$refCss}>
      {entity && description.picture ? (
        <ImageField object={entity} field={description.picture} />
      ) : null}
      {text}
    </span>
  )
}

const $refCss = css`
  display: flex;
  align-items: center;
  > img {
    margin-right: var(--spacer-xs);
  }
`

const applyJoin = (object: IEntity, params: IJoinParameter) => {
  const separator = params.separator ?? ', '
  return params.fields
    .map((f) => get(object, f) ?? null)
    .filter(isString)
    .join(separator)
}

export const ImageField: FC<{ object: object; field: string; className?: string }> = ({
  object,
  field,
  className,
}) => {
  const url = useMemo(() => decodeUrl(get(object, field)), [object, field])
  return !url?.href ? null : <img className={className} src={url.href} alt={''} css={$css} />
}

const $css = css`
  display: block;
  object-fit: contain;
  height: calc(30rem / var(--bfs));
  width: calc(30rem / var(--bfs));
`

const decodeUrl = (v: unknown) => {
  if (typeof v !== 'string') {
    return null
  }
  let href = decodeURI(v)
  if (href.includes('%')) {
    href = decodeURIComponent(v)
  }
  let url
  try {
    let str = href
    const index = str.indexOf('://')
    if (index !== -1) {
      str = `http${str.slice(index)}`
    }
    url = new URL(str)
  } catch (e) {}
  return { href, url }
}
