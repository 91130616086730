/** @jsx jsx */
import { jsx } from '@emotion/react'
import { createContext, FC, useCallback, useContext, useState } from 'react'
import { IAssignment } from '../../IAssignment'
import { EditAssignmentModal } from './EditAssignmentModal'
import { DeleteModal } from './DeleteModal'

type IModalState = IEditModal | IDeleteModal | null
type IUpdateModal = (modal: IModalState) => void
const ModalCtx = createContext<IUpdateModal>(null!)

type IEditModal = {
  type: 'edit'
  props: {
    assignment: IAssignment
  }
}
type IDeleteModal = {
  type: 'delete'
  props: {
    assignment: IAssignment
  }
}

export const useModal = () => {
  return useContext(ModalCtx)
}

export const ModalProvider: FC = ({ children }) => {
  const [modal, setModal] = useState<IModalState>(null)
  const onClose = useCallback(() => setModal(null), [])
  let view = null
  if (modal !== null) {
    switch (modal.type) {
      case 'delete':
        view = <DeleteModal assignment={modal.props.assignment} onClose={onClose} />
        break
      case 'edit':
        view = <EditAssignmentModal assignment={modal.props.assignment} onClose={onClose} />
    }
  }
  return (
    <ModalCtx.Provider value={setModal}>
      {children}
      {view}
    </ModalCtx.Provider>
  )
}
