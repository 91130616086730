import React, { FC, useContext } from 'react'
import { defaultLocale, Locale } from '../locale'

const LocaleContext = React.createContext(defaultLocale)

export const useLocale = () => useContext(LocaleContext)

export const LocaleProvider: FC<{ value?: Locale }> = ({ value = defaultLocale, children }) => {
  return <LocaleContext.Provider value={value}>{children}</LocaleContext.Provider>
}
