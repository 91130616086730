/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { useEntity } from '../../hooks/useEntity'
import { IAssignment } from '../../IAssignment'
import { Button } from 'primereact/button'
import { useToastRef } from '../../hooks/useToastRef'
import { useLocale } from '../../hooks/useLocale'
import { createCamerasConnection } from '@netvision/lib-api-gateway'
import { E2EModule } from '../../__test__'

const handleCommand = (promise: Promise<unknown>) => {
  return promise
    .then(() => true)
    .catch((e: unknown) => {
      console.error(e)
      return false
    })
}

const startAssignments = (id: string | undefined) =>
  (id &&
    handleCommand(
      createCamerasConnection().v2.updateEntityAttributes(
        { type: 'AssignmentGroup', id, startCommand: {} },
        {
          keyValues: true,
        },
      ),
    )) ||
  new Promise(() => true)

const stopAssignments = (id: string | undefined) =>
  (id &&
    handleCommand(
      createCamerasConnection().v2.updateEntityAttributes(
        { type: 'AssignmentGroup', id, stopCommand: {} },
        {
          keyValues: true,
        },
      ),
    )) ||
  new Promise(() => true)

export type IJoinParameter = {
  fields: string[]
  separator?: string
}

export function AssignmentGroup(props: { rowData: IAssignment }) {
  const locale = useLocale()
  const toastRef = useToastRef()
  const { rowData } = props
  const id = rowData.groupId
  const status = rowData?.status
  const [loading, _, entity] = useEntity('AssignmentGroup', id || '')
  const text = entity?.title || ''
  if (loading) {
    return null
  }
  const onError = (command: 'start' | 'stop' | 'restart') => {
    toastRef.current?.show({
      severity: 'error',
      summary: locale.commands.error,
      detail: locale.commands.onGroupCommandErrors[command],
    })
  }
  return text.length === 0 ? (
    <span data-cy={E2EModule.attributes.assignmentGroup}>{'-'}</span>
  ) : (
    <div data-cy={E2EModule.attributes.assignmentGroup} css={cCss} className="p-d-flex">
      <span css={$refCss}>{text}</span>
      <div
        css={css`
          flex-grow: 1;
        `}
      />
      <Button
        className={'p-button-text'}
        data-cy={E2EModule.attributes.analyticsGroupStart}
        data-status={status || 'unAvailable'}
        onClick={() => startAssignments(rowData.groupId).then((res) => !res && onError('start'))}
        icon={'mdi mdi-20px mdi-loading mdi-play'}
      />
      <Button
        className={'p-button-text'}
        data-cy={E2EModule.attributes.analyticsGroupStop}
        data-status={status || 'unAvailable'}
        icon={'mdi mdi-20px mdi-loading mdi-pause'}
        onClick={() => stopAssignments(rowData.groupId).then((res) => !res && onError('start'))}
        tooltipOptions={{ position: 'left' }}
      />
    </div>
  )
}

const $refCss = css`
  display: flex;
  align-items: center;
  > img {
    margin-right: var(--spacer-xs);
  }
`
const cCss = css`
  & {
    .p-button-text {
      color: var(--text-color-secondary);

      :enabled:hover {
        color: var(--text-color);
      }

      :enabled:active {
        color: var(--text-color-secondary);
        filter: brightness(0.7);
      }
    }
  }
`
