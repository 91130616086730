/** @jsx jsx */
import { jsx } from '@emotion/react'
import { FC, Fragment } from 'react'
import { useDialogVisible } from './useDialogVisible'
import { IAssignment } from '../../IAssignment'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { deleteAssignment, assignmentsCount } from '../../assignmentCommands'
import { useForceLoad } from './ForceLoadProvider'
import { useLocale } from '../../hooks/useLocale'
import { useToastRef } from '../../hooks/useToastRef'

export const DeleteModal: FC<{ assignment: IAssignment; onClose: () => void }> = ({
  assignment,
  onClose: _onHide,
}) => {
  const locale = useLocale()
  const [visible, onHide] = useDialogVisible(_onHide)
  const { id, groupId } = assignment
  const forceLoad = useForceLoad()
  const footer = (
    <Fragment>
      <Button
        className={'p-button-danger'}
        label={locale.delete}
        onClick={() =>
          deleteAssignment(id)
            .then(async () => {
              toastRef.current?.show({
                severity: 'success',
                summary: locale.success,
                detail: locale.deleteDialog.success,
              })
              onHide()
              forceLoad()
              groupId !== undefined &&
                (await assignmentsCount(`groupId==${groupId}`)) === 0 &&
                (await deleteAssignment(groupId, 'AssignmentGroup'))
            })
            .catch((e: Error) => {
              console.error(e)
              toastRef.current?.show({
                severity: 'error',
                summary: locale.error,
                detail: locale.deleteDialog.error,
              })
            })
        }
      />
      <Button className={'p-button-secondary'} label={locale.cancel} onClick={onHide} />
    </Fragment>
  )
  const toastRef = useToastRef()
  return (
    <Dialog header={locale.deleteDialog.header} visible={visible} onHide={onHide} footer={footer}>
      <div style={{ padding: '0 var(--form-padding)' }}>{locale.deleteDialog.message}</div>
    </Dialog>
  )
}
