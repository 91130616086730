import { createCamerasConnection } from '@netvision/lib-api-gateway'

const handleCommand = (promise: Promise<unknown>) => {
  return promise
    .then(() => true)
    .catch((e: unknown) => {
      console.error(e)
      return false
    })
}

export const assignmentsCount = async (q: string): Promise<number> => {
  try {
    const options = {
      type: 'Assignment',
      limit: 1,
      count: true,
    } as Record<string, unknown>
    q && (options.q = q)
    const { count } = await createCamerasConnection().v2.listEntities(options)
    return count
  } catch (error) {
    console.error(error)
    return Infinity
  }
}

export const startAssignment = (id: string) =>
  handleCommand(
    createCamerasConnection().v2.updateEntityAttributes(
      { type: 'Assignment', id, startCommand: {} },
      {
        keyValues: true,
      },
    ),
  )

export const stopAssignment = (id: string) =>
  handleCommand(
    createCamerasConnection().v2.updateEntityAttributes(
      { type: 'Assignment', id, stopCommand: {} },
      {
        keyValues: true,
      },
    ),
  )

export const deleteAssignment = (id: string, type = 'Assignment') =>
  handleCommand(createCamerasConnection().v2.deleteEntity({ type, id }))
