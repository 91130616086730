import { getPermissionsByIdsMap } from '@netvision/lib-api-gateway'
import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { createBatchQueue } from '../../utils/batchQueue'

type ScopesResolver = {
  scopes: Set<string>
  clear: Array<() => void>
}

type Ctx<K> = (id: string) => Promise<{
  scopes: Set<K>
  clear: Array<() => void>
}>
const AssignmentPermissionCtx = createContext<Ctx<any>>(null!)
const aSet = <T,>() => new Set<T>()
const clear = (arr: string[]) => Array.from(new Set(arr))

export const usePermissionScopes = <K extends string>(id: string): Set<K> => {
  const getScopes = useContext(AssignmentPermissionCtx)
  const clearRef = useRef<Array<() => void>>()
  const [scopes, setRes] = useState<Set<K>>(aSet)
  const [_, forceUpdate] = useState(false)

  useEffect(() => {
    const disposer = clearRef.current

    return () => {
      disposer?.forEach(clear => clear())
    }
  }, [_])

  useEffect(() => {
    getScopes(id)
      .then(({ clear, scopes }) => {
        setRes(scopes)
        clearRef.current = clear
        forceUpdate(prev => !prev)
      })
      .catch(() => setRes(aSet))
  }, [id, getScopes])

  return scopes
}

export const AssignmentPermissionsProvider: FC = ({ children }) => {
  const [cache] = useState(() => new Map<string, ScopesResolver>())

  const batchQ = useMemo(
    () =>
      createBatchQueue<string, Set<string>>(100, (inputs) => {
        return getPermissionsByIdsMap(clear(inputs.map(({ args }) => args)), 'resourceId').then(
          (map) => {
            return inputs.map(({ args, $id }) => {
              return {
                $id,
                result: new Set(map.get(args)),
              }
            })
          },
        )
      }),
    [],
  )
  const getScopes = useCallback(
    (id: string) => {
      const cacheData = cache.get(id)
      if (cacheData) {
        return Promise.resolve({
          scopes: cacheData.scopes,
          clear: cacheData.clear,
        })
      } else {
        return new Promise<ScopesResolver>((resolve) => {
          const clearBatch = batchQ.add(
            id,
            () => false,
            (scopes) => {
              const clearCache = () => cache.delete(id)
              const result = {
                scopes,
                clear: [clearCache, clearBatch],
              }

              cache.set(id, result)
              resolve(result)
            },
          ) as unknown as () => void
        })
      }
    },
    [batchQ, cache],
  )

  return (
    <AssignmentPermissionCtx.Provider value={getScopes}>
      {children}
    </AssignmentPermissionCtx.Provider>
  )
}
