import { AssignmentStatus } from './AssignmentStatus'

const assignmentStatusLocale: Record<AssignmentStatus, string> = {
  Rejected: 'Отказано',
  Stopped: 'Остановлена',
  WaitForStarting: 'Ожидается запуск',
  NotStarted: 'Не запущена',
  Starting: 'Запускается',
  Stopping: 'Останавливается',
  Started: 'Запущена',
}

const assignmentRejectReason: Record<string, string> = {
  ByTimeout: 'Таймаут ответа от аналитики',
  ByError: 'Аналитики прервали обработку видеопотока',
  IsNotConfirmed: 'Аналитики не подтвердили запуск из-за невалидных данных параметров',
  external_ByError: 'Внешние аналитики не подтвердили запуск видеопотока',
  external_ByTimeout: 'Поток недоступен',
  external_CameraPositionInvalidated: 'Произошла смена положения PTZ камеры',
}

const deleteDialog = {
  header: 'Удалить',
  message: 'Удалить аналитику с объекта?',
  success: 'Аналитика успешно удалена',
  error: 'Не удалось удалить аналитику',
}

const commands = {
  start: 'Начать',
  stop: 'Остановить',
  restart: 'Перезапустить',
  onCommandDetails: {
    start: 'Аналитика запускается',
    restart: 'Аналитика перезапускается',
    stop: 'Аналитика останаливается',
  },
  onGroupCommandErrors: {
    start: 'Не удалось запустить группу аналитик',
    restart: 'Не удалось перезапустить группу аналитик',
    stop: 'Не удалось остановить группу аналитик',
  },
  onCommandErrors: {
    start: 'Не удалось запустить аналитику',
    restart: 'Не удалось перезапустить аналитику',
    stop: 'Не удалось остановить аналитику',
  },
  presetError: 'Аналитика не может быть запущена из текущией позиции камеры',
  error: 'Ошибка',
  success: 'Успешно',
}

export const defaultLocale = {
  assignmentStatusLocale,
  assignmentRejectReason,
  deleteDialog,
  rejectReason: 'Последняя ошибка',
  back: 'Назад',
  error: 'Ошибка',
  success: 'Успешно',
  cancel: 'Отмена',
  delete: 'Удалить',
  title: 'Аналитики',
  assignmentGroup: 'Группа аналитик',
  assignmentNameLabel: 'Название',
  assignmentTypeLabel: 'Тип аналитики',
  assignmentTypeId: 'Тип аналитики',
  priorityLabel: 'Приоритет',
  entityLabel: 'Объект',
  entityIdLabel: 'ID объекта',
  entityTypeLabel: 'Тип объекта',
  statusLabel: 'Статус',
  presetLabel: 'Позиция камеры',
  presetIdLabel: 'ID позиции',
  search: 'Поиск',
  searchByName: 'Поиск по названию',
  noRecords: 'Аналитики отсутствуют',
  canStart: 'Запускаема',
  commands,
}

export type Locale = typeof defaultLocale
