import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { createCamerasConnection, IEntity, listEntities } from '@netvision/lib-api-gateway'
import { createBatchQueue, IBatchQueue } from '../utils/batchQueue'

type IFetchEntity = <T extends IEntity>(type: T['type'], id: T['id']) => Promise<T | null>

export const FetchEntityCtx = createContext<{
  fetchEntity: IFetchEntity
  resetCache: () => void
}>(null!)

export const useEntityCacheReset = () => {
  return useContext(FetchEntityCtx).resetCache
}

export const useEntity = <T extends IEntity>(
  type: string,
  id: string,
): [loading: boolean, error: boolean, entity: T | null] => {
  const { fetchEntity } = useContext(FetchEntityCtx)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [entity, setEntity] = useState<T | null>(null)
  useEffect(() => {
    if (type.length === 0 || id.length === 0) {
      setEntity(null)
      setLoading(false)
      return
    }
    let aborted = false
    setEntity(null)
    setLoading(true)
    fetchEntity<T>(type, id)
      .then((en) => {
        if (!aborted) {
          setEntity(en)
        }
      })
      .catch((err) => {
        console.error(err)
        if (!aborted) {
          setError(true)
        }
      })
      .finally(() => {
        if (!aborted) {
          setLoading(false)
        }
      })
    return () => {
      aborted = true
    }
  }, [fetchEntity, type, id])
  return [loading, error, entity]
}

export const useEntities = (type: string, ids: string[]) => {
  const { fetchEntity } = useContext(FetchEntityCtx)
  const [loading, setLoading] = useState(false)
  const [entityMap, setEntityMap] = useState<Map<string, IEntity | null>>(aMap)
  const idKey = useMemo(() => JSON.stringify([...new Set(ids)].sort()), [ids])
  useEffect(() => {
    const idList = JSON.parse(idKey) as string[]
    if (type.length === 0 || idList.length === 0) {
      setEntityMap(aMap)
      setLoading(false)
      return
    }
    let aborted = false
    setEntityMap(aMap)
    setLoading(true)
    const pAll = idList.map((id) =>
      fetchEntity(type, id)
        .then((en) => {
          if (!aborted && en) {
            setEntityMap((prev) => {
              prev.set(id, en)
              return new Map(prev)
            })
          }
        })
        .catch((err) => {
          console.error(err)
        }),
    )
    Promise.all(pAll).finally(() => {
      if (!aborted) {
        setLoading(false)
      }
    })
    return () => {
      aborted = true
    }
  }, [fetchEntity, type, idKey])
  return [loading, entityMap] as const
}

export const FetchEntityProvider: FC = ({ children }) => {
  type _IQueue = IBatchQueue<[string, string], IEntity>

  const [batchMap] = useState<Map<string, _IQueue>>(aMap)
  const [cache, setCache] = useState<Map<string, IEntity>>(aMap)

  const fetchEntity = useCallback<IFetchEntity>(
    (type, id) => {
      return new Promise((resolve, reject) => {
        const cacheKey = JSON.stringify([type, id])
        const cached = cache.get(cacheKey)
        if (cached) {
          resolve(cached as any)
          return
        }
        // Only one queue per each entity
        // so multiple rows with same entity will make only one request
        // TODO make one per type, when support for comma-separated list of ids will be fixed on backend
        const queueKey = JSON.stringify([type, id])
        let queue: _IQueue | undefined = batchMap.get(queueKey)
        if (typeof queue === 'undefined') {
          queue = createEntityQueue()
          batchMap.set(queueKey, queue)
        }
        queue.add([type, id] as [string, string], reject, (output) => {
          cache.set(cacheKey, output)
          resolve(output as any)
        })
      })
    },
    [cache, batchMap],
  )
  const resetCache = useCallback(() => {
    setCache(aMap())
  }, [])
  const value = useMemo(() => ({ fetchEntity, resetCache }), [fetchEntity, resetCache])
  return <FetchEntityCtx.Provider value={value}>{children}</FetchEntityCtx.Provider>
}

const createEntityQueue = () =>
  createBatchQueue<[string, string], IEntity>(34, (input) => {
    if (input.length > 0) {
      const [type, id] = input[0].args
      return listEntities(createCamerasConnection(), { type, id })
        .then(({ results }) => results)
        .then((res) => {
          if (res[0]) {
            return input.map(({ $id }) => ({ $id, result: res[0] }))
          } else {
            return []
          }
        })
    } else {
      return Promise.resolve([])
    }
  })

const aMap = <K, T>() => new Map<K, T>()
