import { createCamerasConnection, IEntity, listEntities } from '@netvision/lib-api-gateway'
import { get } from 'lodash-es'
import { ngsiCaseInsensitive } from './utils/ngsiCaseInsensitive'
import { ngsiSanitize } from './utils/ngsiSanitize'

export const searchById = <E extends IEntity>(
  type: IEntity['type'],
  id: string | string[],
): Promise<E[]> => {
  id = join(id, ',')
  return id.length > 0 ? load({ id, type: join(type, ',') }) : Promise.resolve([])
}

export const searchByField = <E extends IEntity>(
  type: IEntity['type'],
  query: string,
  field: string,
  limit: number,
): Promise<E[]> =>
  load({
    type: join(type, ','),
    orderBy: field,
    limit,
    q: query.length > 0 ? `${field}~=${ngsiCaseInsensitive(ngsiSanitize(query))}` : undefined,
  })

export const load = <E extends IEntity>(params: {
  type: string
  id?: string
  q?: string
  limit?: number
  orderBy?: string
}): Promise<E[]> =>
  listEntities(createCamerasConnection(), {
    ...params,
  })
    .then((response) => (response?.results ?? []) as E[])
    .catch(() => [])

export const join = (s: string | string[], separator: string) =>
  Array.isArray(s) ? s.join(separator) : s

export const optionsFromEntities = (labelField: string) => (entities: IEntity[]) => {
  return entities.map((en) => ({ value: en.id, label: get(en, labelField) as string }))
}
