/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { FC, useEffect, useRef } from 'react'
import { useDialogVisible } from './useDialogVisible'
import { useWidgetProps } from '../../Root'
import { Widget } from '@netvision/lib-widget-renderer'
import { IWidgetProps } from '../../IWidgetProps'
import { IAssignment } from '../../IAssignment'

export const EditAssignmentModal: FC<{ assignment: IAssignment; onClose: () => void }> = ({
  assignment,
  onClose: _onHide,
}) => {
  const [visible, onHide] = useDialogVisible(_onHide)
  return (
    <div css={[$editModal, $visible]} data-visible={visible}>
      <div css={$content}>
        <Inner assignment={assignment} onClose={onHide} />
      </div>
    </div>
  )
}

const $editModal = css`
  position: absolute;
  z-index: 999;
  top: 0;
  width: 100vw;
  background: var(--body-bg);
`

const $visible = css`
  &[data-visible] {
    transition: opacity 1s ease;
  }
  &[data-visible='true'] {
    opacity: 1;
  }
  &[data-visible='false'] {
    opacity: 0;
  }
`

const $content = css`
  height: calc(100vh);
  overflow: auto;
`

const Inner: FC<{ assignment: IAssignment; onClose: () => void }> = ({ assignment, onClose }) => {
  const { mountChildren, areas } = useWidgetProps()
  const ref = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    const node = ref.current
    const { dialog } = getAreasChildren(areas)
    if (node && dialog) {
      return mountChildren(node, [
        {
          ...dialog,
          props: {
            ...dialog.props,
            entityId: assignment.entityId,
            entityType: assignment.entityType,
            assignmentId: assignment.id,
            assignmentGroupId: assignment.groupId,
            onClose,
          },
        },
      ])
    }
    return undefined
  }, [assignment, mountChildren, areas, onClose])
  return <div ref={ref} />
}

function getAreasChildren(areas: IWidgetProps['areas']): {
  dialog: Widget | null
} {
  let dialog = null
  if (Array.isArray(areas)) {
    areas.forEach((area) => {
      if (area.name === 'dialog' && area.children && area.children.length > 0) {
        dialog = area.children[0]
      }
    })
  }
  return {
    dialog,
  }
}
