/* eslint-disable react/prop-types */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { FC, useCallback, useEffect, useState } from 'react'
import { IAssignment } from '../../IAssignment'
import { Button } from 'primereact/button'
import { usePermissionScopes } from './AssignmentPermissionsProvider'
import { AssignmentStatus } from '../../AssignmentStatus'
import { useModal } from './ModalProvider'
import { startAssignment, stopAssignment } from '../../assignmentCommands'
import { useLocale } from '../../hooks/useLocale'
import { useToastRef } from '../../hooks/useToastRef'
import { useMountedRef } from '../../hooks/useMountedRef'
import { E2EModule } from '../../__test__'

type ButtonProps = {
  icon?: string
  onClick?: () => void
  style?: Record<string, string>
  disabled?: boolean
  tooltip?: string
}

type IAssignmentScopes =
  | 'StartAssignment'
  | 'StopAssignment'
  | 'UpdateAssignment'
  | 'DeleteAssignment'

export const Status: FC<{ assignment: IAssignment }> = ({ assignment }) => {
  const scopes = usePermissionScopes<IAssignmentScopes>(assignment.resourceId)
  const statusIs = useCallback(
    (...args: AssignmentStatus[]) => args.includes(assignment.status),
    [assignment.status],
  )
  const setModal = useModal()
  return (
    <div css={cCss}>
      <StatusButton assignment={assignment} />
      {scopes.has('UpdateAssignment') && (
        <Button
          data-cy={E2EModule.attributes.analyticsButtonUpdate}
          onClick={() => setModal({ type: 'edit', props: { assignment } })}
          className={'p-button-text'}
          icon={'mdi mdi-24px mdi-pencil'}
        />
      )}
      {scopes.has('DeleteAssignment') && (
        <Button
          onClick={() => setModal({ type: 'delete', props: { assignment } })}
          data-cy={E2EModule.attributes.analyticsButtonDelete}
          disabled={statusIs(
            AssignmentStatus.Starting,
            AssignmentStatus.Started,
            AssignmentStatus.Stopping,
          )}
          className={'p-button-text'}
          icon={'mdi mdi-24px mdi-delete'}
        />
      )}
    </div>
  )
}
// language=SCSS
const cCss = css`
  & {
    margin-top: calc(-10rem / var(--bfs));
    margin-left: calc(-15rem / var(--bfs));
    margin-bottom: calc(-10rem / var(--bfs));

    .p-button-text {
      color: var(--text-color-secondary);

      :enabled:hover {
        color: var(--text-color);
      }

      :enabled:active {
        color: var(--text-color-secondary);
        filter: brightness(0.7);
      }
    }
  }
`

const StatusButton: FC<{ assignment: IAssignment }> = ({ assignment }) => {
  const locale = useLocale()
  const scopes = usePermissionScopes<IAssignmentScopes>(assignment.resourceId)
  const [loading, setLoading] = useState(false)
  const mountedRef = useMountedRef()
  useEffect(() => {
    setLoading(false)
  }, [assignment])
  const toastRef = useToastRef()
  const onSuccess = (command: 'start' | 'stop' | 'restart') => {
    toastRef.current?.show({
      severity: 'success',
      summary: locale.commands.success,
      detail: locale.commands.onCommandDetails[command],
    })
  }
  const onError = (command: 'start' | 'stop' | 'restart') => {
    toastRef.current?.show({
      severity: 'error',
      summary: locale.commands.error,
      detail: locale.commands.onCommandErrors[command],
    })
  }
  const onCommand = (command: 'start' | 'stop' | 'restart') => {
    setLoading(true)
    switch (command) {
      case 'start':
      case 'restart':
        startAssignment(assignment.id)
          .then((res) => {
            if (res) {
              onSuccess(command)
            } else {
              onError(command)
            }
          })
          .finally(() => {
            if (mountedRef.current) {
              setLoading(false)
            }
          })
        break
      case 'stop':
      default:
        stopAssignment(assignment.id)
          .then((res) => {
            if (res) {
              onSuccess(command)
            } else {
              onError(command)
            }
          })
          .finally(() => {
            if (mountedRef.current) {
              setLoading(false)
            }
          })
    }
  }
  let props = undefined as Partial<ButtonProps> | undefined
  switch (assignment.status) {
    case undefined:
      props = {
        icon: 'mdi mdi-24px mdi-play',
        onClick: () => false,
        disabled: true,
      }
      break
    case AssignmentStatus.NotStarted:
    case AssignmentStatus.Stopped:
      props = {
        icon: 'mdi mdi-24px mdi-play',
        onClick: () => onCommand('start'),
        disabled: !scopes.has('StartAssignment') || !assignment.canStart,
        tooltip: `${locale.assignmentStatusLocale[assignment.status]}\xa0- ${
          locale.commands.start
        }`,
      }
      break
    case AssignmentStatus.Started:
      props = {
        icon: 'mdi mdi-24px mdi-pause',
        onClick: () => onCommand('stop'),
        disabled: !scopes.has('StopAssignment'),
        tooltip: `${locale.assignmentStatusLocale[assignment.status]}\xa0- ${locale.commands.stop}`,
      }
      break
    case AssignmentStatus.Rejected:
      props = {
        icon: 'mdi mdi-24px mdi-restore-alert',
        onClick: () => onCommand('restart'),
        style: { color: 'var(--error-color)' },
        disabled: !scopes.has('StartAssignment') || !assignment.canStart,
        tooltip: `${locale.assignmentStatusLocale[assignment.status]}\xa0- ${
          locale.commands.restart
        }`,
      }
      break
    case AssignmentStatus.Starting:
    case AssignmentStatus.WaitForStarting:
    case AssignmentStatus.Stopping:
      props = {
        icon: 'mdi mdi-24px mdi-spin mdi-loading',
        style: { cursor: 'wait' },
        disabled: true,
        tooltip: locale.assignmentStatusLocale[assignment.status],
      }
  }
  return props ? (
    <Button
      disabled={loading || props.disabled}
      className={'p-button-text'}
      icon={loading ? 'mdi mdi-24px mdi-loading mdi-spin' : props.icon}
      style={props.style}
      data-cy={E2EModule.attributes.analyticsButtonStatus}
      data-status={assignment.status || 'unAvailable'}
      onClick={props.onClick}
      tooltip={props.tooltip}
      tooltipOptions={{ position: 'left' }}
    />
  ) : null
}
