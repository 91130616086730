import { useCallback, useEffect, useState } from 'react'
import { useMountedRef } from '../../hooks/useMountedRef'

export const useDialogVisible = (onHide: () => void): [boolean, () => void] => {
  const mountedRef = useMountedRef()
  const [visible, setVisible] = useState(false)
  useEffect(() => {
    setVisible(true)
  }, [])
  return [
    visible,
    useCallback(() => {
      setVisible(false)
      setTimeout(() => {
        if (mountedRef.current) {
          onHide()
        }
      }, 200)
    }, [onHide, mountedRef]),
  ]
}
