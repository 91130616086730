/** @jsx jsx */
import { FC } from 'react'
import { css, jsx } from '@emotion/react'
import { E2EModule } from '../../__test__'

export const Title: FC<{ value: string; error: boolean }> = ({ value, error }) => {
  return value ? (
    <span
      data-cy={E2EModule.attributes.assignmentEventName}
      css={error === true ? $titleError : $title}>
      {value}
    </span>
  ) : null
}

// language=SCSS
const $title = css`
  & {
    font-weight: 500;
    color: var(--text-color);
  }
`
// language=SCSS
const $titleError = css`
  & {
    font-weight: 500;
    color: var(--error-color-dark);
  }
`
