import { useEffect, useState } from 'react'
import { Connection, IEntity, listEntities } from '@netvision/lib-api-gateway'
import { useMountedRef } from './useMountedRef'

export const factoryUseEntityList = <E extends IEntity>(
  type: E['type'],
  createConnection: () => Connection,
) =>
  function useEntityList() {
    const mountedRef = useMountedRef()
    const [entities, setEntities] = useState<E[]>([])
    useEffect(() => {
      listEntities<E>(createConnection(), { type, limit: 1000 }).then((res) => {
        if (mountedRef.current) {
          setEntities(res.results)
        }
      })
    }, [mountedRef])
    return entities
  }
