export const ngsiCaseInsensitive = (string: string) => {
  return string
    .split('')
    .map((char) => {
      if (char.match(/[a-zа-я]/i)) {
        return `[${char.toLowerCase()}${char.toUpperCase()}]`
      } else {
        return char
      }
    })
    .join('')
}
